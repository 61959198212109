<ion-header class="ion-no-border" *ngIf="!!post">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="settings()"
        *ngIf="!(view.isDesktop || (!view.startManually && (!post || !post.post_content || !post.post_content.length)))"
        fill="clear" color="dark" icon-only>
        <ion-icon [name]="!!view.settingsView ? 'settings' : 'settings-outline'" slot="start"></ion-icon>
      </ion-button>

      <ion-button icon-only fill="clear" (click)="share()" color="dark"
        *ngIf="!(!view.startManually && (!post || !post.post_content || !post.post_content.length))">
        <ion-icon name="hammer-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <ion-button (click)="create()" color="primary" shape="round" fill="solid" [hidden]="!view.startManually"
        [disabled]="!post.post_content || !post.name || !view.canPublish" *ngIf="!view.canUpdate">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="update()" color="primary" shape="round" fill="solid"
        [disabled]="!post.post_content || !post.name || !view.canUpdate" *ngIf="!view.canPublish">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true" *ngIf="!!post" [class.settings-view]="!!view.settingsView">

  <ion-button *ngIf="!!posts && !!posts.length" fill="clear" color="primary" shape="round" class="nav-btn back"
    icon-only [disabled]="!view.canNavigateBack" (click)="navBack()">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </ion-button>

  <ion-button *ngIf="!!posts && !!posts.length" fill="clear" color="primary" shape="round" class="nav-btn next"
    icon-only [disabled]="!view.canNavigateNext" (click)="navNext()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>

  <!-- Create post intro mode -->
  <div class="tool-intro-wrapper" *ngIf="!!post && !post.uid && !view.startManually && !post.post_content">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'create_post_ai_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <ion-textarea [rows]="view.isDesktop ? 1 : 2" [(ngModel)]="view.createPostInput" [disabled]="view.loading"
          [placeholder]="'create_post_ai_helper_input_placeholder'|translate"></ion-textarea>

        <pipeline-channels-picker #introChannelsPicker [disabled]="false" [item]="post"
          (changed)="onChannelsChanged($event)"></pipeline-channels-picker>
        <div *ngIf="!!view.types && !!view.types.length">

          <ion-card-header>
            <ion-card-title>
              <span [innerHTML]="'post_types_headline'|translate"></span>
            </ion-card-title>
          </ion-card-header>

          <ion-grid class="types-grid">
            <ion-row>

              <ion-col size="6" *ngFor="let type of view.types; let iType = index;">

                <ion-button class="types-btn" [class.active]="type.checked" size="block"
                  [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                  <ion-icon [name]="type.icon"></ion-icon>
                  <ion-label [innerHTML]="type.name|translate"></ion-label>
                </ion-button>

              </ion-col>

            </ion-row>
          </ion-grid>
        </div>
        -->

        <ion-grid>
          <ion-row>

            <ion-col [size]="view.isDesktop ? 6 : 12">

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
                [disabled]="!view.createPostInput || !view.createPostInput.length">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>

            </ion-col>

            <ion-col [size]="view.isDesktop ? 6 : 12">
              <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
                <ion-icon name="create-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_post_manually'|translate"></ion-label>
              </ion-button>
            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-card>

    </div>
  </div>

  <!-- Post edit mode-->
  <div class="container" [class.has-nav]="!!posts && !!posts.length">

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !view.settingsView">

          <!-- Types -->
          <!--
          <ion-card *ngIf="!!view.types && !!view.types.length">

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'post_types_headline'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'post_types_text'|translate"></p>
            </ion-card-content>

            <ion-grid class="types-grid">
              <ion-row>

                <ion-col size="6" *ngFor="let type of view.types; let iType = index;">

                  <ion-button class="types-btn" [class.active]="type.checked" size="block"
                    [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                    <ion-icon [name]="type.icon"></ion-icon>
                    <ion-label [innerHTML]="type.name|translate"></ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>
          -->

          <!-- step 1: configure post-->
          <pipeline-post-settings [(post)]="post" [view]="view"
            (settingsChanged)="onPostSettingsChanged($event)"></pipeline-post-settings>

          <!-- step 2: select post media -->
          <pipeline-media-picker-card [(item)]="post" [(items)]="view.mediaList" [(view)]="view"
            (onMediaChanged)="onMediaChanged($event)"></pipeline-media-picker-card>

          <!-- ai styles -->
          <pipeline-ai-style-picker *ngIf="!!post && !!post.uid" [(view)]="view"></pipeline-ai-style-picker>

          <!-- Channels -->
          <!-- (!!view.selectedTypesList && !!view.selectedTypesList.social) &&  -->
          <!--<pipeline-channels-picker
            *ngIf="!!post && !!post.uid"
            [hidden]="!view.hasSelectedTypes" [item]="post"></pipeline-channels-picker>
          -->

          <!-- step 3: create social media caption -->
          <!-- (!!view.selectedTypesList && !!view.selectedTypesList.social) &&  -->
          <pipeline-caption-generator [config]="captionGeneratorConfig" [(post)]="post"
            [disabled]="!post || !post.post_content" [(view)]="view"
            (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>

          <!-- step 4: create voiceover (optional) -->
          <pipeline-voiceover-card [options]="{ input: (view.audioInputText || '')}"
            (settingsChanged)="onVoiceoverSettingsChanged($event)"
            *ngIf="!!post && !!view.audioInputText"></pipeline-voiceover-card>

        </ion-col>

        <!-- Main content -->
        <ion-col [size]="view.isDesktop ? (!!post.uid && !!view.expertMode ? 6 : 9) : 12"
          [hidden]="!view.isDesktop && !!view.settingsView">

          <div class="history-wrapper">

            <div class="history-list" #historyWrapper>

              <div class="history-item" *ngFor="let item of view.history; let i = index; trackBy:trackItems">

                <ion-card *ngIf="item.mode === 'view'" [class.role-assistant]="item.role === 'assistant'"
                  [class.role-user]="item.role === 'user'">

                  <ion-card-content>
                    <div [class.code-preview]="!!item.post_content_formatted"
                      [innerHTML]="item.post_content_formatted || item.post_content"></div>
                  </ion-card-content>

                  <ion-card-header *ngIf="!!i && (item.role !== 'user')">
                    <pipeline-rate-item-buttons [(item)]="view.history[i]" type="idea"></pipeline-rate-item-buttons>
                  </ion-card-header>

                </ion-card>

                <ion-card *ngIf="item.mode !== 'view'" class="editor">

                  <pipeline-editor [id]="'postEditor' + (i+1)" [disabled]="!!view.loading" [input]="item.post_content"
                    (inputChange)="onEditorInputChanged($event, item, i)"></pipeline-editor>

                  <ion-item lines="none" *ngIf="!!i">

                    <pipeline-rate-item-buttons [(item)]="view.history[i]" type="post"
                      slot="start"></pipeline-rate-item-buttons>

                    <ion-button slot="end" fill="clear" (click)="useAsPrimary(view.history[i], i)" color="dark"
                      icon-only>
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </div>

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

            </div>

          </div>

          <ion-card class="aiPromptCard">

            <form (ngSubmit)="runAiPrompt()">

              <ion-item lines="none">

                <ion-textarea name="aiPrompt" #aiPromptPostInput rows="2" [(ngModel)]="view.aiPrompt"
                  [disabled]="!!view.loading" [placeholder]="'ai_prompt_post_modification'|translate"></ion-textarea>

                <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
                  [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
                  <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
                  <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
                </ion-button>

              </ion-item>

            </form>

          </ion-card>

        </ion-col>

        <!-- Statistics & comments -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" *ngIf="!!post && !!post.uid"
          [hidden]="!view.expertMode">

          <!-- Statistics -->
          <ion-card class="sidebar-accordion-card statistics-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'statistics'|translate"></span>
              </ion-card-title>

              <ion-grid>
                <ion-row>

                  <!-- Comments -->
                  <ion-col size="6">
                    <ion-card>
                      <ion-card-header>
                        <h1
                          [innerHTML]="!!view.loading || !!view.loadingComments ? '...' : (!!view.comments && !!view.comments.length ? view.comments.length : 0)">
                          ...</h1>
                        <ion-card-subtitle [innerHTML]="'comments'|translate"></ion-card-subtitle>
                      </ion-card-header>
                    </ion-card>
                  </ion-col>

                  <!-- Likes -->
                  <ion-col size="6">
                    <ion-card>
                      <ion-card-header>
                        <h1>...</h1>
                        <ion-card-subtitle [innerHTML]="'likes'|translate"></ion-card-subtitle>
                      </ion-card-header>
                    </ion-card>
                  </ion-col>

                  <!-- Shares -->
                  <ion-col size="6">
                    <ion-card>
                      <ion-card-header>
                        <h1>...</h1>
                        <ion-card-subtitle [innerHTML]="'shares'|translate"></ion-card-subtitle>
                      </ion-card-header>
                    </ion-card>
                  </ion-col>

                  <!-- Views -->
                  <ion-col size="6">
                    <ion-card>
                      <ion-card-header>
                        <h1>...</h1>
                        <ion-card-subtitle [innerHTML]="'views'|translate"></ion-card-subtitle>
                      </ion-card-header>
                    </ion-card>
                  </ion-col>

                </ion-row>
              </ion-grid>

              <!--
              <pipeline-chart *ngIf="!!view.expertMode && !!view.statistics && !!view.statistics.length && !!view.chart"
                [chart]="view.chart"></pipeline-chart>

              <pipeline-no-entries-card *ngIf="!view.chart || (!view.statistics || !view.statistics.length)"
                [hidden]="!!view.loading"></pipeline-no-entries-card>
              -->

            </ion-card-header>

          </ion-card>

          <!-- Comments -->
          <ion-card class="sidebar-accordion-card comments-card" *ngIf="!!post && !!post.uid">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'comments'|translate"></span>
              </ion-card-title>

              <ion-button class="right" (click)="syncComments()" icon-only color="dark" fill="clear">
                <ion-icon name="sync-outline"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-comments-list *ngIf="!view.rebuild" type="post" [uid]="post.uid" [context]="post.post_content"
              (onChanged)="onCommentsChanged($event)"></pipeline-comments-list>

          </ion-card>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!post && (!!post.uid || !!view.startManually)">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-button icon-only fill="clear" (click)="toolsMenu()" color="dark">
        <ion-icon name="sparkles-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'ai_tools'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <ion-button (click)="toggleLanguage()" icon-only [disabled]="!post.uid">
        <ion-icon name="language-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'translations'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-item lines="none" *ngIf="!!view.expertMode && !!view.ai.config.model">
        <ion-icon name="thermometer-outline" slot="start"></ion-icon>
        <ion-input [label]="'temperature'|translate" labelPlacement="stacked" type="number" [step]="0.1" [min]="0"
          [max]="1" [(ngModel)]="view.ai.config.temperature"></ion-input>
      </ion-item>

      <!--
      <ion-item lines="none" [hidden]="!view.expertMode" (click)="chooseProvider()">
        <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'provider'|translate" placement="stacked"></ion-label>
        <ion-note [innerHTML]="(view.ai.config.provider || 'select')|translate" slot="end"></ion-note>
      </ion-item>
      -->

      <ion-item button lines="none" [hidden]="!view.expertMode" (click)="chooseModel()">
        <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'model'|translate" placement="stacked"></ion-label>
        <ion-note [innerHTML]="(view.ai.config.model || 'select')|translate" [hidden]="!view.isDesktop"
          slot="end"></ion-note>
      </ion-item>

      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>

    </ion-buttons>

  </ion-toolbar>
</ion-footer>