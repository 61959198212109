import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { HashService } from 'src/app/services/core/hash.service';
import { NetworkService } from 'src/app/services/core/network.service';

import { apiUrl } from 'src/config/variables';

@Injectable({
  providedIn: 'root'
})
export class AppcmsService {

  api: apiConfig = {
    version: 3,
  };

  apiCredentials: any;

  apiUrl: string;

  // this will store the original api url, if changed programatically
  _apiUrl: string;

  blUseAuthorization: boolean = true;

  filters: any = {};

  mainApiUrl: string = apiUrl;

  requestParams: any = {};

  user: user;

  constructor(
    private cache: CacheService,
    private hash: HashService,
    private http: HttpClient,
    private network: NetworkService,
  ) {
    this.apiUrl = this.getApiUrl() || this.getMainApiUrl();
  }

  executeLoadPluginData(
    plugin: string,
    data: any = {},
    actions: any = undefined,
    params: any = {},
  ) {
    params = params || {};

    return new Promise((resolve, reject) => {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const url: string = this.getRequestUrl(plugin, actions, params);

      if (this.blUseAuthorization) {
        data.user = data.user || this.getApiCredentials();
        data.userId = data.userId || this.getUserUid();
      }

      this.http.post(url, JSON.stringify(data), { headers: headers as any })
        .subscribe(
          (response: any) => {
            if (!response) {
              reject('error_unknown');
            } else
              if (response.success !== false && !response.message) {
                resolve(response);
              } else {
                reject(response.message || 'error_unknown');
              }
          },
          async (error: any) => {
            let message: string = error.message;
            const blLoaded: boolean = (error && error.error) ? !!error.error.loaded : false;

            if (message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
              message = 'Wir arbeiten zurzeit an Updates und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
            }

            if (!blLoaded) {
              this.network.showOfflineMessage();
              reject('error_offline');
            } else {
              reject(message || 'error_unknown');
            }
          }
        );
    });
  }

  getApiCredentials(user: user | null = null) {
    user = user || this.user;
    return {
      'email': user.email,
      'password': user.password,
    };
  }

  getApiUrl() {
    return this.apiUrl || this.getMainApiUrl();
  }

  getApiVersion() {
    return this.api.version;
  }

  getFilter(key: string) {
    return this.filters[key];
  }

  getFilters() {
    return this.filters;
  }

  getMainApiUrl() {
    return this.mainApiUrl;
  }

  getRequestParams() {
    return this.requestParams;
  }

  getRequestUrl(
    plugin: string,
    actions: any = undefined,
    params: any = {},
  ) {
    params = params || {};

    const action = (actions ? typeof actions === 'string' ? actions : actions.join('/') : null);
    let url: string;

    if (!!action) {
      url = (params.apiUrl || this.getApiUrl()) + '/' + plugin + '/' + action + '.json';
    } else {
      url = (params.apiUrl || this.getApiUrl()) + '/' + plugin + '.json';
    }

    return url;
  }

  getUserUid(user: user | null = null) {
    user = user || this.user;
    return user && user.uid ? user.uid : null;
  }

  isMainApi() {
    return this.getApiUrl() === this.getMainApiUrl();
  }

  loadPluginData(
    plugin: string,
    data: any = {},
    actions: any = undefined,
    params: any = {},
    blForceRefresh: boolean = true
  ) {
    return new Promise(async (resolve, reject) => {
      data = data || {};

      try {

        let postData = Object.assign({
          version: data.version || this.getApiVersion(),
        }, data);

        const requestParams: any = this.getRequestParams();

        if (!!requestParams) {
          postData = Object.assign(postData, requestParams);
        }

        if (data.hasOwnProperty('project_uid')) {
          postData.project_uid = parseInt(`${data.project_uid}`);
        }

        const key: number = this.hash.hashCode(`${JSON.stringify(postData || {})}_${JSON.stringify(actions || [])}_${JSON.stringify(params || {})}`),
          fromCacheKey: string = `pipeline_${plugin}_${key}`;

        if (!blForceRefresh) {
          const fromCache: cacheItem = await this.cache.get(fromCacheKey);

          if (!!fromCache && !!fromCache.data) {
            resolve(fromCache.data);
          } else {
            this.executeLoadPluginData(
              plugin, postData, actions, params
            )
              .then((response: any) => {
                this.cache.set(fromCacheKey, response);
                resolve(response);
              }).catch(reject);
          }
        } else {
          this.executeLoadPluginData(
            plugin, postData, actions, params
          )
            .then((response: any) => {
              this.cache.set(fromCacheKey, response);
              resolve(response);
            }).catch(reject);
        }
      } catch (e) {
        reject(e.message || e);
      }
    });
  }

  loadUrl(url: string, data: any = {}, blForceReturnContent: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(url)
          .subscribe(
            (response: any) => {
              if (!!blForceReturnContent) {
                resolve(response);
              } else
                if (!response) {
                  reject('error_unknown');
                } else
                  if (response.success !== false && !response.message) {
                    resolve(response);
                  } else {
                    reject(response.message || 'error_unknown');
                  }
            },
            (response: any) => {
              if (!!blForceReturnContent) {
                resolve(response && response.error && response.error.text ? response.error.text : response);
              } else {
                let message = response.message;
                if (message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
                  message = 'Wir arbeiten aktuell an unserer App und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
                }
                console.warn('appcms error 2', response);
                reject(message || 'error_unknown');
              }
            }
          );
      } catch (e) {
        reject(e.message);
      }
    });
  }

  postPluginData(plugin, method = 'create', data = {}) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getApiUrl() + '/' + plugin + '/' + method + '.json', data, {})
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  resetApiUrl() {
    this.apiUrl = this._apiUrl || this.getMainApiUrl();
    return this;
  }

  setApiUrl(apiUrl: string) {

    if (!this._apiUrl) {
      this._apiUrl = `${this.apiUrl}`;
    }

    this.apiUrl = apiUrl;

    return this;
  }

  setFilter(key: string, value: any) {
    this.filters[key] = value;
    return this;
  }

  setRequestParam(key: string, value: any) {
    this.requestParams[key] = value;
    return this;
  }

  storeCurrentAuthDetails(user: user) {
    this.user = user;
    return this;
  }

}